import React, { useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import styles from "./HeaderNavigation.module.scss";
import Hamburger from "./Hamburger/Hamburger";

import { TweenMax, SlowMo } from "gsap";

const HeaderNavigation = () => {
  let animeLinks = useRef([]);

  useEffect(() => {
    TweenMax.staggerFrom(
      animeLinks.current,
      2,
      {
        opacity: 0,
        x: "-30",
        ease: SlowMo.easeInOut
      },
      0.2
    );
  }, []);

  return (
    <>
      <nav className={styles.nav}>
        <Hamburger />
        <ul className={styles.menu}>
          <li
            className={styles.menuItem}
            ref={el => (animeLinks.current[0] = el)}
          >
            <NavLink
              exact
              className={styles.menuLink}
              activeClassName={styles.active}
              to="/"
            >
              Strona główna
            </NavLink>
          </li>
          <li
            className={styles.menuItem}
            ref={el => (animeLinks.current[1] = el)}
          >
            <NavLink
              className={styles.menuLink}
              activeClassName={styles.active}
              to="/o-mnie"
            >
              O mnie
            </NavLink>
          </li>
          <li
            className={styles.menuItem}
            ref={el => (animeLinks.current[2] = el)}
          >
            <NavLink
              className={styles.menuLink}
              activeClassName={styles.active}
              to="/oferta"
            >
              Oferta
            </NavLink>
          </li>
          <li
            className={styles.menuItem}
            ref={el => (animeLinks.current[3] = el)}
          >
            <NavLink
              className={styles.menuLink}
              activeClassName={styles.active}
              to="/kontakt"
            >
              Kontakt
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default HeaderNavigation;
