import React from "react";
import styles from "./Article.module.scss";

const Article = ({ title, text }) => {
  return (
    <>
      <article className={styles.article}>
        <h2 className={styles.articleTitle}>{title}</h2>
        <p className={styles.articleText}>{text}</p>
      </article>
    </>
  );
};

export default Article;
