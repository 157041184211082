import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./Button.module.scss";

import { TweenMax, Power4 } from "gsap";

const Button = ({
  tag: Tag,
  type,
  href = "/kontakt",
  title,
  primary,
  secondary,
  children,
  customClass
}) => {
  const buttonPrimary = primary ? styles.buttonPrimary : styles.button;
  const buttonSecondary = secondary ? styles.buttonSecondary : styles.button;

  let buttonElement = useRef(null);

  useEffect(() => {
    TweenMax.from(buttonElement, 1, {
      opacity: 0,
      x: "-100",
      ease: Power4.easeInOut,
      delay: 1.5
    });
  }, []);

  return (
    <>
      <Tag
        ref={el => (buttonElement = el)}
        href={Tag === "a" ? href : undefined}
        title={title}
        className={`${customClass} ${styles.button} ${buttonPrimary} ${buttonSecondary}`}
        type={type}
      >
        {children}
      </Tag>
    </>
  );
};

Button.propTypes = {
  tag: PropTypes.string,
  type: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  children: PropTypes.string,
  customClass: PropTypes.string
};

Button.defaultProps = {
  tag: "a",
  type: "text",
  customClass: "btn"
};

export default Button;
