import React, { useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../../assets/img/logo-wojciech-klimczak.svg";
import styles from "./Branding.module.scss";

import { TweenMax, SlowMo } from "gsap";

const Branding = () => {
  let brand = useRef();

  useEffect(() => {
    TweenMax.from(brand, 1.5, { opacity: 0, x: "-30", ease: SlowMo.easeInOut });
  });

  return (
    <>
      <NavLink
        exact
        to="/"
        className={styles.branding}
        activeClassName={styles.brandingActive}
      >
        <img
          ref={el => (brand = el)}
          className={styles.brandingLogo}
          src={Logo}
          alt="Wojciech Klimczak logo"
        />
      </NavLink>
    </>
  );
};

export default Branding;
