import React from "react";
import HeaderNavigation from "./HeaderNavigation/HeaderNavigation";
import Branding from "./Branding/Branding";
import styles from "./Header.module.scss";

const Header = () => {
  return (
    <>
      <header className={styles.header}>
        <Branding />
        <HeaderNavigation />
      </header>
    </>
  );
};

export default Header;
