import React from "react";
import ReactDOM from "react-dom";
import styles from "./index.module.scss";

// Root view
import Root from "./views/Root/Root";

ReactDOM.render(
  <Root className={styles.root} />,
  document.getElementById("root")
);
