import React from "react";
import styles from "./Footer.module.scss";
import Phone from "../Phone/Phone";

const Footer = () => {
  return (
    <>
      <footer className={styles.footer}>
        <Phone number="501 350 575" />
        <p className={styles.author}>
          Projekt i wykonanie:&nbsp;
          <a
            target="_blank"
            href="https://interakcjo.pl"
            title="Interakcjo.pl"
            rel="noopener noreferrer"
          >
            Interakcjo.pl
          </a>
        </p>
      </footer>
    </>
  );
};

export default Footer;
