import React from "react";
import PropTypes from "prop-types";
import styles from "./Input.module.scss";

const Input = ({ tag: Tag, type, name, label, maxLength, value, ...props }) => (
  <>
    <div className={styles.formRow}>
      <Tag
        className={Tag === "textarea" ? styles.formTextarea : styles.formInput}
        type={type}
        name={name}
        id={name}
        required
        maxLength={maxLength}
        placeholder=" "
        value={value}
        {...props}
      />
      <label
        className={
          value === ""
            ? styles.formLabel
            : [styles.formLabel, styles.formLabelActive].join(" ")
        }
        htmlFor={name}
      >
        {label}
      </label>
    </div>
  </>
);

Input.propTypes = {
  tag: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  value: PropTypes.string
};

Input.defaultProps = {
  tag: "input",
  maxLength: 200
};

export default Input;
