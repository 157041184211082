import React, { Component } from "react";
import styles from "./Form.module.scss";

import Button from "../Button/Button";
import Input from "../Input/Input";

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: ""
    };
  }

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  sendMessage = e => {
    e.preventDefault();

    const newMessage = {
      name: e.target[0].value,
      email: e.target[1].value,
      phone: e.target[2].value,
      message: e.target[3].value
    };

    this.setState(() => ({
      newMessage
    }));

    e.target.reset();
  };

  render() {
    return (
      <>
        <form
          action="https://producentmuzyczny.pl/send-mail.php"
          method="POST"
          className={styles.form}
        >
          <Input
            onChange={this.handleInputChange}
            value={this.state.name}
            type="text"
            name="name"
            label="Imię i nazwisko"
          />
          <Input
            onChange={this.handleInputChange}
            value={this.state.email}
            type="email"
            name="email"
            label="Adres email"
          />
          <Input
            onChange={this.handleInputChange}
            value={this.state.phone}
            type="text"
            name="phone"
            label="Numer telefonu (opcjonalnie)"
          />
          <Input
            tag="textarea"
            onChange={this.handleInputChange}
            value={this.state.message}
            name="message"
            label="Treść wiadomości"
          />
          <Button
            tag="button"
            type="submit"
            primary
            customClass={styles.formButton}
          >
            Wyślij wiadomość
          </Button>
        </form>
      </>
    );
  }
}

export default Form;
