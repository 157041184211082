import React, { useRef, useEffect } from "react";
import styles from "./GoToContentButton.module.scss";
import Arrow from "../../assets/img/arrow-to-content.svg";

import { TweenMax, Power4 } from "gsap";

const GoToContentButton = ({ children }) => {
  let goToContentElement = useRef(null);

  useEffect(() => {
    TweenMax.from(goToContentElement, 0.8, {
      opacity: 0,
      y: "-100",
      ease: Power4.easeInOut,
      delay: 1.2
    });

    goToContentElement.addEventListener("click", function() {
      if (document.querySelector("main")) {
        const mainPos = document.querySelector("main").getBoundingClientRect()
          .top;

        window.scrollTo({
          top: mainPos,
          behavior: "smooth"
        });
      }
    });
  }, []);

  return (
    <>
      <button
        className={styles.button}
        ref={el => {
          goToContentElement = el;
        }}
      >
        {children}
        <img src={Arrow} alt="" />
      </button>
    </>
  );
};

export default GoToContentButton;
