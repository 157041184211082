import React from "react";
import Hero from "../../components/Hero/Hero";
import HeroImage from "../../assets/img/photos-1600px/wojciech-klimczak-image-7.jpg";
import styles from "./Kontakt.module.scss";

import Form from "../../components/Form/Form";
import MapContainer from "../../components/GoogleMap/GoogleMap";
import ContactImage from "../../assets/img/contact-image.jpg";
import PhoneCta from "../../components/PhoneCta/PhoneCta";

class Kontakt extends React.Component {
  state = {
    title: "Zapraszam do kontaktu",
    imgSRC: HeroImage,
    imgALT: "Wojciech Klimczak śpiewa do mikrofonu."
  };

  render() {
    const stringArray = this.state.title.split(" ");
    const myCustomTitle = stringArray.map((letter, index) => (
      <span key={index} className={styles.word}>
        {letter}
      </span>
    ));

    return (
      <>
        <Hero
          title={myCustomTitle}
          imgSRC={this.state.imgSRC}
          imgALT={this.state.imgALT}
          NoButton
        />
        <main>
          <PhoneCta text="Bardzo chętnie porozmawiam z Tobą przez telefon, zapraszam!" />

          <div className={styles.contactWrapper}>
            <Form />
            <img className={styles.contactImage} src={ContactImage} alt="" />
          </div>

          <MapContainer />

          <p className={styles.contactText}>
            Przy okazji życzę każdemu samych <span>sukcesów</span>
          </p>
        </main>
      </>
    );
  }
}

export default Kontakt;
