import React from "react";
import Hero from "../../components/Hero/Hero";
import HeroImage from "../../assets/img/photos-1600px/wojciech-klimczak-image-2.jpg";
import styles from "./Oferta.module.scss";
import Article from "../../components/Article/Article";
import Cta from "../../components/Cta/Cta";

import OfferImageOne from "../../assets/img/photos-1600px/wojciech-klimczak-image-3.jpg";
import OfferImageTwo from "../../assets/img/photos-1600px/wojciech-klimczak-image-10.jpg";
import OfferImageThree from "../../assets/img/photos/wojciech-klimczak-image-vert-3.jpg";

class Oferta extends React.Component {
  state = {
    title: "Oferta Pod Twój Rytm",
    imgSRC: HeroImage,
    imgALT: "Wojciech Klimczak idzie po parkiecie tanecznym."
  };

  render() {
    const stringArray = this.state.title.split(" ");
    const myCustomTitle = stringArray.map((letter, index) => (
      <span key={index} className={styles.word}>
        {letter}
      </span>
    ));

    return (
      <>
        <Hero
          title={myCustomTitle}
          imgSRC={this.state.imgSRC}
          imgALT={this.state.imgALT}
        />
        <main className={styles.main}>
          <section className={styles.offer}>
            <div className={styles.offerCol}>
              <Article
                title="Jestem ELastyczny i otwarty na nowe pomysły"
                text="Może dla niektórych będzie to zaskoczeniem lecz nie mam czegoś takiego jak szablonowa oferta czy jakikolwiek cennik usług. Projekty, czy biznesowo mówiąc – zlecenia, są przeróżne i przeróżna jest w nich moja rola. Nie raz wyrywa mi miesiące z życiorysu a czasami ogranicza się do przekazania namiarów na odpowiednich fachowców. Dzisiaj muzyka praktycznie wymaga jakiegoś obrazu. Również w zakresie produkcji audio/video działam niezmiernie chętnie. Czasami projekt wymaga ode mnie zupełnie nowej roli stąd wszelkie kwestie poboczne są szczegółowo dyskutowane, jestem otwarty na każdy pomysł."
              />
              <div className={styles.offerImage}>
                <img
                  src={OfferImageOne}
                  alt="Wojciech Klimczak śpiewa na scenie wraz z zespołem."
                />
              </div>
            </div>

            <div className={styles.offerCol}>
              <Article
                title="Jak nawiązać ze mną współpracę?"
                text="Napisz czego potrzebujesz i postaram się pomóc. A najlepiej od razu zadzwoń – akustycznie zawsze jest szybciej i efektywniej 😉 Technicznie moją specjalizacją jest napisanie utworu w określonym celu, jego kompozycja i aranżacja, miks kreatywny i techniczny oraz mastering. Jest nią również realizacja i pomoc w organizowaniu sesji nagraniowych począwszy od rozpisania harmonogramu po dobór najodpowiedniejszej obsady personalnej. W praktyce jednak współpraca ze mną często jest daleka od specjalizacji technicznych. Często ma jakiś wspólny fundament z moimi działaniami na płaszczyźnie biznesowej aczkolwiek nie jest to warunkiem wspólnych działań. Jestem zaangażowany w szereg projektów, opiekuję się kilkoma zespołami, wokalistami i artystami – jeżeli brak jest wspólnych fundamentów zawsze można takowe stworzyć."
              />
              <div className={styles.offerImage}>
                <img
                  src={OfferImageTwo}
                  alt="Wojciech Klimczak i wokalistka śpiewają razem."
                />
              </div>
            </div>

            <div className={styles.offerCol}>
              <div className={styles.offerColArticlesWrapper}>
                <Article
                  title="Każdy projekt to szansa na rozwój"
                  text="Jak wygląda praca ze mną? Przede wszystkim przebiega sprawnie, szybko i konkretnie. Jeżeli decyduję się na współpracę zaczynam już tego samego dnia i dokładam wszelkich starań aby choć najtrudniejsze nawet kwestie zamykać tak szybko jak się da. Podobnego, sprawnego działania oczekuję również od pomysłodawców współpracy (nie lubię tutaj słowa klient gdyż nigdy tak nie postrzegam osób, z jakimi wspólnie tworzę muzykę lub przy niej współpracuję)."
                />
                <Article
                  title="Wykonuję szereg usług, pomagam wielu osobom"
                  text=" Ciężko konkretnie zaadresować moje usługi gdyż zgłaszają się do mnie autentycznie przeróżni ludzie: od męża, który nagrał zdradę swojej żony marnej jakości telefonem, przez nastoletnich wykonawców próbujących zaistnieć na rynku showbiznesu, uczestników przeróżnych talentshow, którzy na wczoraj potrzebują utworu do dalszej promocji, agencje reklamowe, twórcy filmów, gier, youtuberzy, wytwórnie i wydawnictwa muzyczne mniejsze i większe, zespoły muzyczne każdego gatunku, wokaliści z całego kraju, artyści o przeróżnym statusie popularności, aktorzy, sklepy wielkopowierzchniowe, biznesmeni pragnący zainwestować w obiecujących muzyków czy wokalistów na przemiłym człowieku, który nagrał muzyczny prezent dla swoich wnuczków kończąc…"
                />
              </div>
              <div className={styles.offerImage}>
                <img
                  src={OfferImageThree}
                  alt="Wojciech Klimczak śpiewa do mikrofonu, w srebrnej marynarce."
                />
              </div>
            </div>

            <p className={styles.offerText}>
              Staram się pomóc w każdym przypadku lecz na wszystkie po prostu
              nie wystarcza czasu. Mam na szczęście wokół siebie wielu
              profesjonalistów z branży, którym ufam i których usługi mogę z
              czystym sumieniem polecić.
            </p>

            <Cta text="Jeżeli chodzi Ci po głowie cokolwiek związanego z muzyką - <a style='color: #fed766; text-decoration: none;' href='tel:501350575' title='Zadzwoń'>zadzwoń</a>. Ja to składam w całość i realizuję." />
          </section>
        </main>
      </>
    );
  }
}

export default Oferta;
