import React from "react";
import Hero from "../../components/Hero/Hero";
import HeroImage from "../../assets/img/photos-1600px/wojciech-klimczak-image-6.jpg";
import styles from "./StronaGlowna.module.scss";

class StronaGlowna extends React.Component {
  state = {
    title: "Producent Muzyczny",
    imgSRC: HeroImage,
    imgALT:
      "Producent Muzyczny Wojciech Klimczak siedzący przy konsoli w studiu nagraniowym."
  };

  render() {
    const stringArray = this.state.title.split(" ");
    const myCustomTitle = stringArray.map((letter, index) => (
      <span key={index} className={styles.word}>
        {letter}
      </span>
    ));

    return (
      <>
        <Hero
          title={myCustomTitle}
          imgSRC={this.state.imgSRC}
          imgALT={this.state.imgALT}
          NoArrow
          HomePageTitle
          HomePageImage
        />
      </>
    );
  }
}

export default StronaGlowna;
