import React from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import styles from "./GoogleMap.module.scss";

const mapStyles = {
  width: "100%",
  height: "450px"
};

export class MapContainer extends React.Component {
  render() {
    return (
      <div className={styles.googleMapContainer}>
        <Map
          google={this.props.google}
          zoom={15}
          initialCenter={{
            lat: 50.036123,
            lng: 20.144155
          }}
          style={mapStyles}
        >
          <Marker onClick={this.onMarkerClick} name={"B&B Records"} />
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBFyR-uXxwUfNOCAM9C_GRERCPt3jKS3uo"
})(MapContainer);
