import React from "react";
import styles from "./PhoneCta.module.scss";

import Phone from "../Phone/Phone";

const PhoneCta = ({ text }) => {
  return (
    <aside className={styles.phoneCta}>
      <h2 className={styles.phoneCtaTitle}>{text}</h2>
      <Phone theme="dark" number="501 350 575" />
    </aside>
  );
};

export default PhoneCta;
