import React from "react";
import Hero from "../../components/Hero/Hero";
import HeroImage from "../../assets/img/photos-1600px/wojciech-klimczak-image-1.jpg";
import Article from "../../components/Article/Article";
import Cta from "../../components/Cta/Cta";

import AboutImageOne from "../../assets/img/photos/wojciech-klimczak-image-vert-1.jpg";
import AboutImageTwo from "../../assets/img/photos/wojciech-klimczak-image-vert-2.jpg";
import styles from "./Omnie.module.scss";

class Omnie extends React.Component {
  state = {
    title: "Moja muzyczna historia",
    imgSRC: HeroImage,
    imgALT: "Wojciech Klimczak siedzi w studiu nagraniowym."
  };

  render() {
    const stringArray = this.state.title.split(" ");
    const myCustomTitle = stringArray.map((letter, index) => (
      <span key={index} className={styles.word}>
        {letter}
      </span>
    ));

    return (
      <>
        <Hero
          title={myCustomTitle}
          imgSRC={this.state.imgSRC}
          imgALT={this.state.imgALT}
          NoButton
          DiffrentButtonProps
        />
        <main className={styles.main}>
          <section className={styles.about}>
            <div className={styles.aboutCol}>
              <Article
                title="Kiedy i jak to się zaczeło"
                text="W roku 1994 rozpoczęło działalność jedno z największych do dzisiaj studiów nagraniowych w Polsce – studio B&B Records. Jego założycielem był wybitny basista oraz przedsiębiorca Bogdan Klimczak – mój tata. Dzięki takiemu zrządzeniu losu mając raptem 12 lat miałem możliwość nieograniczonego rozwoju w zakresie realizacji dźwięku oraz pracy w studiu. Już w wieku 13 lat rozpocząłem komponowanie i realizację swoich pierwszych utworów co błyskawicznie stało się moją życiową pasją."
              />
              <div className={styles.aboutImage}>
                <img
                  src={AboutImageOne}
                  alt="Wojciech Klimczak gra na gitarze, w tle jest dym i światła."
                />
              </div>
            </div>

            <div className={styles.aboutCol}>
              <div className={styles.aboutImage}>
                <img
                  src={AboutImageTwo}
                  alt="Wojciech Klimczak śpiewa do mikrofonu."
                />
              </div>
              <Article
                title="Produkcja muzyczna to moja pasja"
                text="Do dzisiaj brałem udział lub nadzorowałem setki sesji przeprowadzanych w studiu B&B Records wielu innych. Mam na koncie tysiące różnych aranżacji i muzycznych projektów realizowanych dla amatorów, profesjonalistów oraz najbardziej znanych gwiazd polskiej estrady. Na przestrzeni ponad trzech dekad poznałem osobiście setki osób związanych z branżą muzyczną w Polsce, dysponuję olbrzymią ilością kontaktów w środowisku Polskiej muzyki."
              />
            </div>
          </section>

          <div className={styles.additional}>
            <p className={styles.additional__text}>
            Moje umiejętności nie są ograniczone jedynie do płynnego poruszania w środowisku studia nagrań. Mogę pochwalić się gruntownym wykształceniem muzycznym. Edukację muzyczną rozpocząłem w klasie fortepianu w państwowej placówce ZSPmuz  im. M. Karłowicza w Krakowie, którą wobec świetnych wyników ukończyłem z rocznym wyprzedzeniem harmonogramu.  Dalsza edukacja muzyczna w Państwowym Liceum Muzycznym im. F. Chopina w Krakowie oraz na Akademii Muzycznej w Krakowie ugruntowała niepospolite umiejętności w zakresie instrumentalistyki, kształcenia słuchu, harmonii czy form muzycznych.  Na wszystkich szczeblach muzycznej edukacji uzyskiwałem świetne wyniki, reprezentowałem szkoły  na wielu ogólnopolskich konkursach i przesłuchaniach.
            </p>

            <Cta text="Zycie to na szczęście nie tylko muzyka. Oddaję się kilku innym zamiłowaniom jak koszykówka czy astronomia. Lubię też pograć w szachy choć ostatnie miejsce na jedynych rozegranych zawodach szachowych z pewnością nie można nazwać sukcesem spektakularnym." />
          </div>
        </main>
      </>
    );
  }
}

export default Omnie;
