import React from "react";
import Button from "../Button/Button";
import styles from "./Cta.module.scss";

const Cta = ({ text }) => {

  return (
    <>
    <aside className={styles.cta}>
      <p className={styles.ctaText} dangerouslySetInnerHTML={{
      __html: text
      }}/>
      <Button primary>Przejdź do kontaktu</Button>
    </aside>
    </>
  );
};

export default Cta;
