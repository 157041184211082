import React from "react";
import styles from "./Phone.module.scss";

const Phone = ({ number, theme = "light" }) => {
  const numberTrimmed = number.replace(/\s/g, "");

  return (
    <>
      <a
        href={`tel:${numberTrimmed}`}
        title={number}
        className={
          theme === "light"
            ? styles.phone
            : [styles.phone, styles.phoneDark].join(" ")
        }
        theme={theme}
      >
        {number}
      </a>
    </>
  );
};

export default Phone;
