import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import styles from "./Hero.module.scss";
import GoToContentButton from "../GoToContentButton/GoToContentButton";
import Button from "../Button/Button";

import { TweenMax, SlowMo } from "gsap";

class Hero extends React.Component {
  constructor(props) {
    super(props);
    // reference to the DOM node
    this.heroEl = null;
    this.titleEl = null;

    // reference to the animations
    this.Tween = null;

    //state
    this.state = {
      heroVisibility: true
    };
  }

  componentDidMount() {
    // use the node ref to create the animation
    this.Tween = TweenMax.from(this.heroEl, 2, {
      width: 0,
      x: "-200",
      ease: SlowMo.easeInOut
    });
    this.Tween = TweenMax.from(this.titleEl, 1, {
      opacity: 0,
      y: "-200",
      ease: SlowMo.easeIn,
      delay: 1
    });
  }

  heroOnChange = isVisible => {
    this.setState({
      heroVisibility: isVisible ? true : false
    });
  };

  render() {
    const NoButton = this.props.NoButton;
    const NoArrow = this.props.NoArrow;
    const HomePageTitle = this.props.HomePageTitle;
    const HomePageImage = this.props.HomePageImage;

    return (
      <VisibilitySensor onChange={this.heroOnChange}>
        <section
          style={{ opacity: this.state.heroVisibility ? 1 : 0.25 }}
          className={styles.hero}
          ref={el => (this.heroEl = el)}
        >
          {HomePageTitle ? (
            <h1 className={styles.titleHome} ref={el => (this.titleEl = el)}>
              {this.props.title}
            </h1>
          ) : (
            <h1 className={styles.title} ref={el => (this.titleEl = el)}>
              {this.props.title}
            </h1>
          )}

          {HomePageImage ? (
            <div className={styles.imageWrapperHome}>
              <img
                src={this.props.imgSRC}
                alt={this.props.imgALT}
                className={styles.imageHome}
              />
            </div>
          ) : (
            <div className={styles.imageWrapper}>
              <img
                src={this.props.imgSRC}
                alt={this.props.imgALT}
                className={styles.image}
              />
            </div>
          )}

          {NoButton ? (
            false
          ) : (
            <Button
              tag="a"
              primary
              href="/o-mnie"
              title="O Mnie"
              customClass={styles.hero_button}
            >
              O Mnie
            </Button>
          )}

          {NoArrow ? false : <GoToContentButton />}
        </section>
      </VisibilitySensor>
    );
  }
}

export default Hero;
