import React from "react";
import styles from "./Hamburger.module.scss";

const Hamburger = () => {
  return (
    <button className={styles.menuToggle} type="button">
      <span className={styles.menuToggleLine}></span>
      <span className={styles.menuToggleLine}></span>
      <span className={styles.menuToggleLine}></span>
    </button>
  );
};

export default Hamburger;
