import React from "react";
import AppContext from "../../context";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
// import ChangePageArrow from "../../components/ChangePageArrow/ChangePageArrow";

// Views
import StronaGlowna from "../StronaGlowna/StronaGlowna";
import Omnie from "../Omnie/Omnie";
import Oferta from "../Oferta/Oferta";
import Kontakt from "../Kontakt/Kontakt";

class Root extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <AppContext.Provider>
          <Header />
          <Switch>
            <Route exact path="/" component={StronaGlowna} />
            <Route path="/oferta" component={Oferta} />
            <Route path="/o-mnie" component={Omnie} />
            <Route path="/kontakt" component={Kontakt} />
          </Switch>
          <Footer />
        </AppContext.Provider>
      </BrowserRouter>
    );
  }
}

export default Root;
